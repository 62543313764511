.dashboard-layout {
  display: block;
  height: 100vh;
}

.sidebar {
  flex: 0 0 250px;
  background-color: #f0f0f0;
  padding: 10px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0 0 auto;
}

.card {
  background: var(--surface-card);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.selectedNavItem{
  background-color: aliceblue;  
}

.form-demo .card {
  min-width: 450px;
}

.p-float-label .customNumbers label{
  font-size: 0.9rem
}

.form-demo .card form {
  margin-top: 0.5rem;
}

.form-demo .card .field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
  .dashboard-layout {
    display: block;
    height: 100vh;
  }

  .header {
  }

  .sidebar {
    background-color: #f0f0f0;
    padding: 10px;
  }

  .content {

  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}
